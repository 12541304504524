import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import { TGALoader } from 'three/examples/jsm/loaders/TGALoader';
import { OneMinusDstAlphaFactor, Sphere } from 'three';
//Loading
const textureLoader = new TGALoader();



const normalMap1 = textureLoader.load('/Textures/material1/T_Mine_ObsidianGlass_N.tga')
const roughnessMap1= textureLoader.load('/Textures/material1/T_Mine_ObsidianGlass_R.tga')

// Debug
const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
scene.background = new THREE.Color( "white" );


// Objects

const geometryDonut = new THREE.TorusBufferGeometry(.4, .2, 16, 100);

// Materials

const materialDonut = new THREE.MeshStandardMaterial({
    metalness: 0.4,
    roughness: 1,
    normalMap: normalMap1,
    roughnessMap:roughnessMap1,
    color: new THREE.Color("rgb(40,40,40)"),   
})

// Mesh
const donut = new THREE.Mesh(geometryDonut, materialDonut)
donut.material.depthWrite = true; 
donut.material.transparent = true; 
donut.material.opacity = 1; 
scene.add(donut)

// Lights
const pointLight2 = new THREE.PointLight(0xffffff, 2)
pointLight2.position.set(-3, 1.6, 2.74)
pointLight2.intensity = 1.3

scene.add(pointLight2) 

//Edit Light1
const light1 =gui.addFolder('Light 1')
gui.hide();
light1.add(pointLight2.position, 'x').min(-6).max(6).step(0.01)
light1.add(pointLight2.position, 'y').min(-3).max(3).step(0.01)
light1.add(pointLight2.position, 'z').min(-6).max(6).step(0.01)
light1.add(pointLight2,'intensity').min(0).max(10).step(0.01)

const colorLight1 = {
    color:0xffffff
}
light1.addColor(colorLight1, 'color')
    .onChange(() => {
        pointLight2.color.set(colorLight1.color)
    })

const light = new THREE.AmbientLight( 0x404040 ); // soft white light
scene.add( light );

//Edit Light2
const pointLight3 = new THREE.PointLight(0xffffff, 2)
pointLight3.position.set(0.76, 0, 0.49)
pointLight3.intensity = 0.67

const light2 =gui.addFolder('Light 2')
    
scene.add(pointLight3) 

light2.add(pointLight3.position, 'x').min(-6).max(6).step(0.01)
light2.add(pointLight3.position, 'y').min(-3).max(3).step(0.01)
light2.add(pointLight3.position, 'z').min(-6).max(6).step(0.01)
light2.add(pointLight3,'intensity').min(0).max(10).step(0.01)

const colorLight2 = {
    color:0xffffff
}
light2.addColor(colorLight2, 'color')
    .onChange(() => {
        pointLight3.color.set(colorLight2.color)
    })


const pointLightHelper = new THREE.PointLightHelper(pointLight3, 1)
// scene.add(pointLightHelper)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 2
scene.add(camera)

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha:true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


//gui
// const effect = gui.addFolder('Effect')
// var effect1 = 0.00;
// var rotation = true;
// // var amount = 8;
// donut.visible = true;
// var effectIntensity = {
//     effect1: 0.00,
//     amount: 8,
//     rotation: true,
    
// };
// effect.add(effectIntensity, 'effect1').min(0).max(3).step(0.0001).name('Effect intensity')
// effect.add(effectIntensity, 'amount').min(6).max(64).step(1)
// effect.add(effectIntensity, 'rotation').onChange(function () {
//     rotation = effectIntensity.rotation;
// })

//BUTTON DARKMODE
const colorBlack = new THREE.Color("black");
const colorWhite = new THREE.Color("white");
const colorGrey=new THREE.Color( "rgb(40,40,40)" );
var button = document.getElementById('darkmodebutton');
var buttonTrue = document.getElementById('darkModeTrue');
var buttonFalse = document.getElementById('darkModeFalse');
var dark = false;


button.onclick = function () {
    dark=!dark
    if (!dark) {
        scene.background = colorWhite;
        materialDonut.color = colorGrey;
        materialDonut.metalness = 0.4
        materialDonut.roughness = 1
        buttonTrue.style.visibility = "hidden";
        buttonTrue.style.display = "none";
        buttonFalse.style.visibility = "visible";
        buttonFalse.style.display = "block";
        // buttonText.setAttribute("style", "-webkit-filter:invert(1)");
        // buttonIcon.style.filter = "invert(1)";
        // buttonIcon.setAttribute("style", "-webkit-filter:invert(1)");
        // materialDonut.blendEquation = THREE.MultiplyBlending;
    }
    if (dark) {
        scene.background = colorBlack;
        materialDonut.color = colorWhite
        materialDonut.metalness = 0
        materialDonut.roughness = 0.5
        buttonTrue.style.visibility = "visible";
        buttonTrue.style.display = "block";
        buttonFalse.style.visibility = "hidden";
        buttonFalse.style.display = "none";
        // buttonText.style.filter = "invert(0)";
        // buttonText.setAttribute("style", "-webkit-filter:invert(0)");
        // buttonIcon.style.filter = "invert(0)";
        // buttonIcon.setAttribute("style", "-webkit-filter:invert(0)");

    }
}



var amount = 8;
var intensity = 0;
/**
 * Animate
 */
document.addEventListener('mousemove', onDocumentMouseMove);
let mouseX=0;
let mouseY=0;

let targetX=0;
let targetY=0;

const windowX = window.innerWidth ;
const windowY = window.innerHeight;

let mapX = 0;
let mapY = 0;

function onDocumentMouseMove(event) {
    mouseX = (event.clientX - windowX)
    mouseY = (event.clientY - windowY)

    let value = mouseX
    let x1 = -window.innerWidth
    let y1 = window.innerWidth
    let x2 = -3
    let y2 = 8
    mapX = Math.ceil((value - x1) * (y2 - x2) / (y1 - x1) + x2);

    let valueY = mouseY
    let x1Y = -window.innerHeight
    let y1Y = window.innerHeight
    let x2Y = 0
    let y2Y = 15
    mapY = (valueY - x1Y) * (y2Y - x2Y) / (y1Y - x1Y) + x2Y;

}

const countDonut = geometryDonut.attributes.position.count

const position_cloneDonut = JSON.parse(
    JSON.stringify(geometryDonut.attributes.position.array)
);
const normal_cloneDonut = JSON.parse(
    JSON.stringify(geometryDonut.attributes.normal.array)
)

const damping = 0.2;
const clock = new THREE.Clock()

const tick = () =>
{

    targetX = mouseX * .001;
    targetY = mouseY * .001;
    // console.log("X: " + mapX)
    // console.log("Y: "+mapY)
    const now =Date.now()/300
    
    const elapsedTime = clock.getElapsedTime()

    //find ich auch cool
    // donut.rotation.x = .5 * elapsedTime
    
    
    
    intensity = mapY;
    // amount=mapX
    
        donut.rotation.y += .05 * elapsedTime;
        donut.rotation.y += .1 * (mapX-donut.rotation.y)
    

    

    for (let i = 0; i < countDonut; i++) {
        const uX = geometryDonut.attributes.uv.getX(i) * Math.PI * amount
        const uY = geometryDonut.attributes.uv.getY(i) * Math.PI * amount
    
        const xangle = (uX + now)
        const xsin = Math.sin(xangle) * damping
        const yangle = (uY + now)
        const ycos = Math.cos(yangle) * damping
    
        const ix = i * 3
        const iy = i * 3 + 1
        const iz = i * 3 + 2
        
        geometryDonut.attributes.position
            .setX(i, position_cloneDonut[ix] + normal_cloneDonut[ix] * (xsin + ycos) *0.1*intensity)
        geometryDonut.attributes.position
            .setY(i, position_cloneDonut[iy] + normal_cloneDonut[iy] * (xsin + ycos)*0.1*intensity)
        geometryDonut.attributes.position
            .setZ(i, position_cloneDonut[iz] + normal_cloneDonut[iz] * (xsin + ycos)*0.1*intensity)  
    }
    geometryDonut.computeVertexNormals();
    geometryDonut.attributes.position.needsUpdate = true;
    

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()